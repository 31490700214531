import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { isChrome, isChromium, isFirefox, isEdge } from 'react-device-detect'

import logo from '../images/logo.png'
import chromeIcon from '../images/download-extension-icons/chrome.png'
import firefoxIcon from '../images/download-extension-icons/firefox.png'
import edgeIcon from '../images/download-extension-icons/edge.png'

const Header = styled.header`
  background: ${(props) => props.theme.colors.base};
  width: 100%;
  padding: 1.5em 0;
`

const Logo = styled.div`
  img {
    width: 32px;
    display: inline;
    border: 0;
    padding: 0;
    margin: 0;
  }
  h1 {
    display: inline;
    font-size: 1.6em;
    padding-left: 10px;
  }

  @media only screen and (max-width: 480px) {
    h1 {
      font-size: 20px;
      padding-left: 3px;
    }
  }

  display: flex;
  align-items: center;
`

const Nav = styled.nav`
  width: 100%;
  max-width: ${(props) => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1em;

  ul {
    display: flex;
    justify-content: space-between;
  }

  li {
    display: flex;
    align-items: center;
    margin-left: 1em;
    &:first-child {
      position: relative;
      margin: 0;
      flex-basis: 100%;
    }
  }
`

const MenuLink = styled(Link)`
  text-decoration: none;
  color: white;
  font-weight: 600;
  transition: all 0.2s;
  border-bottom: 2px solid ${(props) => props.theme.colors.base};
  &:hover {
    color: ${(props) => props.theme.colors.highlight};
  }
`

const activeLinkStyle = {
  color: 'white',
}

const ButtonLink = styled.a`
  min-width: 13em;
  padding: 0.4em 0.6em;
  color: white;
  font-weight: 400;
  font-size: 1.15em;
  background-color: #083836;
  box-shadow: 0 4px 16px 0 rgba(255, 255, 255, 0.25);
  background-image: linear-gradient(to right, #083836, #083836);
  display: inline-block;
  border-radius: 50px;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  &:hover {
    color: white;
  }
  @media (max-width: 768px) {
    padding: 0.2em;
    font-size: 0.9em;
    border-radius: 32px;
    min-width: 120px;
  }
  @media (max-width: 480px) {
    display: none;
  }
`

const ButtonIcon = styled.img`
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-right: 2px;
  margin-bottom: -4px;
  padding: 0;
  @media (max-width: 768px) {
    display: none;
    margin: 0;
  }
`

const Menu = () => {
  const extensionButton = () => {
    if (isChrome || isChromium) {
      return (
        <ButtonLink
          target="_blank"
          href="https://chrome.google.com/webstore/detail/cashback-ranking-comparad/afnohhdopilcghjgjaieioofnalhiolk?hl=pt-BR"
        >
          <ButtonIcon
            src={chromeIcon}
            width="22"
            height="22"
            alt="Adicionar extensão ao Chrome"
          />{' '}
          Adicionar ao Chrome
        </ButtonLink>
      )
    } else if (isFirefox) {
      return (
        <ButtonLink
          target="_blank"
          href="https://addons.mozilla.org/pt-BR/firefox/addon/cashback-ranking-brasil/"
        >
          <ButtonIcon
            src={firefoxIcon}
            width="22"
            height="22"
            alt="Adicionar extensão ao Firefox"
          />{' '}
          Adicionar ao Firefox
        </ButtonLink>
      )
    } else if (isEdge) {
      return (
        <ButtonLink
          target="_blank"
          href="https://microsoftedge.microsoft.com/addons/detail/cashback-ranking-compara/mpckdehhfokfncfkpjlbdlielhjncfba"
        >
          <ButtonIcon
            src={edgeIcon}
            width="22"
            height="22"
            alt="Adicionar extensão ao Edge"
          />{' '}
          Adicionar ao Edge
        </ButtonLink>
      )
    } else {
      return <div></div>
    }
  }

  return (
    <Header>
      <Nav>
        <ul>
          <li>
            <MenuLink to="/" activeStyle={activeLinkStyle}>
              <Logo>
                <img
                  src={logo}
                  alt="Cashback Ranking Logo"
                  width="32"
                  height="32"
                />
                <h1>Cashback Ranking</h1>
              </Logo>
            </MenuLink>
          </li>
          <li>
            <MenuLink to="/sobre/" activeStyle={activeLinkStyle}>
              Sobre
            </MenuLink>
          </li>
          <li>
            <MenuLink to="/contato/" activeStyle={activeLinkStyle}>
              Contato
            </MenuLink>
          </li>
          <li>{extensionButton()}</li>
        </ul>
      </Nav>
    </Header>
  )
}

export default Menu
