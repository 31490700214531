import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${(props) => props.theme.sizes.maxWidth};
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.colors.secondary};
  padding: 1em 0 2em;
  margin: 0 1.5em;
`

const Item = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  text-align: center;
  @media screen and (min-width: ${(props) => props.theme.responsive.small}) {
    width: inherit;
  }
  a {
    font-weight: 600;
    transition: all 0.2s;
    text-decoration: none;
    color: ${(props) => props.theme.colors.base};
    &:visited {
      color: ${(props) => props.theme.colors.base};
    }
  }
`

const ItemTerms = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  text-align: center;
  @media screen and (min-width: ${(props) => props.theme.responsive.small}) {
    width: inherit;
  }
  a {
    font-weight: 600;
    transition: all 0.2s;
    text-decoration: none;
    color: #657170;
    &:visited {
      color: #657170;
    }
  }
`

const Footer = () => (
  <Wrapper>
    <List>
      <Item></Item>
      <Item>
        <a href="/" rel="noopener noreferrer" target="_blank">
          © {new Date().getFullYear()} - Cashback Ranking
        </a>
      </Item>
      <Item></Item>
      <Item></Item>
      <ItemTerms>
        <Link to="/terms-of-use">Termos de uso</Link>
      </ItemTerms>
      <ItemTerms>
        <Link to="/privacy-policy">Política de Privacidade</Link>
      </ItemTerms>
    </List>
  </Wrapper>
)

export default Footer
